<template>
  <v-card
    width="100%"
    flat
  >
    <v-row
      no-gutters
    >
      <v-card-title :style="{ borderBottom: '1px solid #ccc', width: '100%', height: '60px', fontSize: '2.5vh' }">
        {{ title }}
      </v-card-title>
    </v-row>
    <v-row
      no-gutters
      :style="{ height: '88%' }"
      class="pt-2"
    >
      <v-col>
        <v-row no-gutters>
          <v-col
            sm="12"
            md="12"
            lg="12"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="d-flex align-center"
          >
            Variáveis:
          </v-col>
          
          <v-col
            sm="12"
            md="12"
            lg="5"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="pt-2"
          >
            <v-card
              max-width="300"
              tile
            >
              <v-list dense>
                <v-list-item-group
                  v-model="selectedItem"
                  color="primary"
                >
                  <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                  >
                    <v-list-item-icon />
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="12"
            md="12"
            lg="12"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="d-flex align-center"
          >
            Orçamento:
          </v-col>
          <v-col
            sm="12"
            md="12"
            lg="6"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="pt-2"
          >
            <v-textarea
              name=""
              label=""
              background-color="amber lighten-4"
              value="Declaro ter recebido e conferido o produto."
            />
          </v-col>
        </v-row>
        <v-row
          no-gutters
        >          
          <v-col
            sm="12"
            md="12"
            lg="12"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="d-flex align-center "
          >
            Consignação:
          </v-col>
          <v-col
            sm="12"
            md="12"
            lg="6"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="d-flex align-center"
          >
            <v-textarea
              name=""
              label=""
              background-color="amber lighten-4"
              value="Declaro ter recebido e conferido o produto."
            />
          </v-col>
        </v-row>
        
        <v-row no-gutters>
          <v-col
            sm="12"
            md="12"
            lg="12"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="d-flex align-center"
          >
            Pedido:
          </v-col>
          <v-col
            sm="12"
            md="12"
            lg="6"
            :cols="$vssWidth < 600 ? 12 : 8"
            class=""
          >
            <v-textarea
              name=""
              label=""
              background-color="amber lighten-4"
              value="Declaro ter recebido e conferido o produto."
            />
          </v-col>         
        </v-row>        
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
  data: () => ({
    selectedItem: 1,
    items: [
      { text: 'Nome do cliente'},
      { text: 'RG'},
      { text: 'CPF/CNPJ' },
      { text: 'Valor do frete' },
      { text: 'Telefone' },
      { text: 'Endereço' },
    ],
  }),
  watch: {

  },
  mounted() {

  },
  methods: {

  },

}
</script>